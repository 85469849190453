export const environment = {
  production: true,
  password: '9ysgqu4el0esxngx',
  terminal: '1699928488358',
  // baseUrl: 'https://bapi.int.nesterov.me',
  baseUrl: 'https://api.betatrader.ru',
  telegram_bot: 'Betatrader_Alerts_bot',
  baseWs: ''
  // baseWs: 'wss://api.betatrader.ru'
};
